import Dropdown from 'bootstrap/js/dist/dropdown';
import Collapse from 'bootstrap/js/dist/collapse';
import Lazysizes from 'lazysizes'

document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll('.navbar .nav-item').forEach(function (everyItem) {
        everyItem.addEventListener('click', function (e) {
            let el_link = this.querySelector('a[data-bs-toggle]');
            if (el_link != null) {
                window.location = el_link.getAttribute('href');
            }
        });

        if (window.innerWidth >= 992) {
            document.querySelectorAll('.navbar .nav-item').forEach(function (everyItem) {
                everyItem.addEventListener('mouseover', function (e) {
                    let el_link = this.querySelector('a[data-bs-toggle]');
                    if (el_link != null) {
                        let nextEl = el_link.nextElementSibling;
                        el_link.classList.add('show');
                        nextEl.classList.add('show');
                    }
                });

                everyItem.addEventListener('mouseleave', function (e) {
                    let el_link = this.querySelector('a[data-bs-toggle]');
                    if (el_link != null) {
                        let nextEl = el_link.nextElementSibling;
                        el_link.classList.remove('show');
                        nextEl.classList.remove('show');
                    }
                })
            });
        }
    });
});
